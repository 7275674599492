import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Nav } from '../../components';
import { WorkSectionHeader } from '../../components';
import GlobalLayout from '../../layouts/GlobalLayout';

const MagicMoney = () => {
  const imgStyleMobile = {
    maxWidth: '320px',
    placeSelf: 'center',
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workType='Concept'
        workName='Magic Money Self-Repaying DeFi Loans'
        what='Alchemix is a protocol that creates self-repaying loans. Unfortunately, the current interface is difficult to use and there are a lot of advanced crypto concepts required for use. I imagined a mobile-first design with complicated transactions (alUSD transmutation, etc) abstracted &amp; simplified. '
        who='My own amusement'
        role='Product Design'
      />

      <ShowcaseContainerMobile>
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/noActiveLoans.png'
        />

        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/explainer/2.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/explainer/3.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/explainer/4.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/explainer/5.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/splash.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/home.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/loanDetail.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/newLoan/1.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/newLoan/2.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/magicMoney/newLoan/3.png'
        />
      </ShowcaseContainerMobile>
    </GlobalLayout>
  );
};

const ShowcaseContainerMobile = styled.section`
  display: flex;
  justify-content: center;
  padding: 3.33em 0;
  max-width: 100vw;
  gap: 2.22em;
  flex-flow: row wrap;
`;

export default MagicMoney;
